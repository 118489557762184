<template>
  <v-dialog
    v-model="fattura"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false"
    scrollable>
    <v-card v-if="ready">
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>
          <span v-if="fattura._id">{{ fattura.codice }}</span>
          <span v-if="!fattura._id">Nuova {{ fattura.tipo }}</span>
          <item-updated
            v-if="fattura._id"
            :mdate="fattura.modifica"
            :muser="fattura.utente" />
          </v-toolbar-title>
        <v-spacer></v-spacer>
        <fattura-status :fattura="fattura" />
        <v-btn icon @click="$router.push({ name: 'fattureStock' })"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-stepper non-linear light v-model="step">
          <v-stepper-header v-show="fattura._id">
            <template v-for="(step, index) in steps">
              <v-stepper-step
                color="primary"
                :key="index + 'step'"
                :step="step.number"
                @click.native="changeStep(index, $event)"
                editable>{{ step.name }}</v-stepper-step>
              <v-divider :key="index + 'divider'" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <v-container fluid grid-list-xl>
                <!-- intestazione -->
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-form v-model="validIntestazione">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <v-text-field
                            label="Numero"
                            :value="fattura.codice"
                            disabled
                          />
                          <date-picker
                            v-model="fattura.data"
                            label="Data*"
                            name="data_fattura"
                            :rules="[$rules.required]"
                            :readonly="true"
                            required />
                          <date-picker
                            v-model="fattura.data_scadenza"
                            label="Data scadenza pagamento*"
                            name="data_scadenza_fattura"
                            :rules="[$rules.required]"
                            :readonly="!fattura.bozza"
                            required />
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-select
                            label="Cliente*"
                            :items="clienti"
                            item-text="ragione_sociale"
                            item-value="cliente_id"
                            v-model="fattura.cliente_id"
                            :rules="[$rules.required]"
                            :disabled="!!fattura._id"
                            @change="changeCliente($event)"
                          />
                          <v-select
                            v-if="fattura.cliente_id"
                            label="Coordinate*"
                            :items="banche"
                            item-value="banca_id"
                            item-text="descrizione"
                            v-model="fattura.banca_id"
                            :rules="[$rules.required]"
                            :disabled="!fattura.bozza"
                          >
                            <template slot="item" slot-scope="data">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  {{ data.item.descrizione}}
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </template>
                          </v-select>
                          <currency-input
                            v-if="fattura.cliente_id"
                            v-model="fattura.marca"
                            label="Marca da bollo"
                            :rules="[$rules.maxlength(6)]"
                            :disabled="!fattura.bozza"
                            name="marca"
                            precision="2"
                          />
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-textarea
                            label="Note"
                            v-model="fattura.note"
                            :disabled="!fattura.bozza"
                          />
                        </v-flex>
                      </v-layout>
                    </v-form>
                    <v-divider class="mt-4 mb-4 pb-4" />
                  </v-flex>
                </v-layout>
                <!-- /intestazione -->
                <!-- voci -->
                <v-layout
                  row
                  wrap
                  v-if="voci && voci.length">
                  <v-flex xs12>
                    <v-list three-line>
                      <template
                        v-for="(voce, index)
                        in voci">
                          <v-list-tile
                            :key="'tile-'+voce.voce_fattura_id">
                            <v-list-tile-content>
                              <v-list-tile-title>
                                {{voce.descrizione}}
                              </v-list-tile-title>
                              <v-list-tile-sub-title v-if="voce.ordine_id">
                                {{ voce.prodotto}} - {{voce.opera}}
                              </v-list-tile-sub-title>
                              <v-list-tile-sub-title>
                                qta. {{voce.qta}} x {{voce.prezzo | currency}}
                              </v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <!--v-icon @click.stop="eliminaVoce(voce)" color="error" v-if="fattura.bozza">delete</v-icon-->
                              <div class="caption">{{voce.imponibile | currency(2)}}</div>
                              <div class="caption">IVA: {{voce.aliquota}}%</div>
                            </v-list-tile-action>
                          </v-list-tile>
                        <v-divider :key="'div-' + index" v-if="index < fattura.voci.length - 1"></v-divider>
                      </template>
                      <v-list-tile>
                        <v-list-tile-content>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <div class="font-weight-bold">tot. {{fattura.totale | currency(2)}}</div>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                </v-layout>
                <!--/voci-->
                <!-- aggiunta voci -->
                <v-layout row wrap v-if="canAddVoce()">
                  <v-flex x12>
                    <v-tooltip left>
                      <v-speed-dial
                          v-model="fab"
                          :bottom="true"
                          :right="true"
                          slot="activator"
                          :fixed="true"
                        >
                          <v-btn
                            slot="activator"
                            v-model="fab"
                            color="pink darken-1"
                            dark
                            fab
                          >
                            <v-icon>add</v-icon>
                            <v-icon>close</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="fattura.tipo === 'nota'"
                            fab
                            dark
                            small
                            color="green"
                            @click.prevent="openVoce()"
                          >
                            <v-icon>edit</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="fattura.tipo === 'fattura'"
                            fab
                            dark
                            small
                            color="indigo"
                            @click.prevent="openVoceStock()"
                          >
                            <v-icon>build</v-icon>
                          </v-btn>
                        </v-speed-dial>
                      <span>Nuova voce</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <!-- /aggiunta voci -->
                <!-- modale voce -->
                <v-dialog
                  persistent
                  v-model="dialogVoce"
                  transition="dialog-bottom-transition"
                  :overlay="false"
                  max-width="700">
                  <v-card>
                    <v-toolbar class="grey lighten-3" card>
                      <v-toolbar-title>{{ voceModalTitle }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="closeVoce()"><v-icon>close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-form ref="formVoce" v-model="validFormVoce">
                        <v-layout row wrap class="voceRow">
                          <v-flex xs12>
                            <v-textarea
                              label="Descrizione*"
                              :rows="2"
                              :rules="[$rules.required]"
                              v-model="voce.descrizione"
                              :disabled="!fattura.bozza"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              label="Quantità*"
                              v-model.number="voce.qta"
                              :rules="[$rules.required, $rules.numeric, $rules.maxlength(6)]"
                              :disabled="!fattura.bozza"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <currency-input
                              v-model="voce.prezzo"
                              label="Prezzo"
                              :rules="[$rules.required]"
                              :disabled="!fattura.bozza"
                              name="Prezzo"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <currency-input
                              disabled
                              v-model="voce.imponibile"
                              :rules="[$rules.required]"
                              label="Importo"
                              name="Importo" />
                          </v-flex>
                          <v-flex xs12>
                            <v-select
                              label="Iva*"
                              :items="iva"
                              item-text="label"
                              item-value="cod_iva"
                              :disabled="!fattura.bozza"
                              v-model="voce.cod_iva" />
                          </v-flex>
                          <v-flex xs12>
                            <v-textarea
                              label="Note"
                              v-model="voce.note"
                              :disabled="!fattura.bozza"
                            />
                          </v-flex>
                          <v-flex xs12 v-if="fattura.bozza">
                            <v-btn
                              :disabled="!validFormVoce"
                              @click="saveVoce()"
                              color="primary">
                              Salva
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- /modale voce -->
                <!-- modale voce stock -->
                <v-dialog
                  v-model="dialogVoceStock"
                  persistent
                  scrollable
                  transition="dialog-bottom-transition"
                  :overlay="false"
                  max-width="700">
                  <v-card>
                    <v-toolbar class="grey lighten-3" card>
                      <v-toolbar-title>Scegli un periodo</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="dialogVoceStock = false"><v-icon>close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text style="height: 300px;" class="mb-4">
                        <v-form v-model="validFormVoceMagazzino">
                          <v-select
                            label="Anno*"
                            :disabled="!fattura.bozza"
                            :rules="[$rules.required]"
                            v-model.number="fattura.anno"
                            :items="anni"
                          />
                          <v-select
                            label="Mese*"
                            :rules="[$rules.required]"
                            v-model.number="fattura.mese"
                            :disabled="!fattura.bozza"
                            :items="mesi"
                          />
                        </v-form>
                    </v-card-text>
                    <v-divider v-if="fattura.bozza" />
                    <v-card-actions v-if="fattura.bozza">
                      <v-btn
                        color="primary"
                        @click="insertVoceStock()"
                        :disabled="false">
                          Calcola e inserisci </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- /modale voce produzione -->
                <!-- / salvataggio -->
                <v-layout row wrap class="mt-4" v-if="step === 1">
                  <v-flex>
                    <v-menu offset-y class="mr-2" :disabled="!validFattura" v-if="fattura.bozza">
                      <v-btn
                        slot="activator"
                        :color="!validFattura ? 'grey' : 'primary'"
                        dark
                      >
                        Salva
                        <v-icon>arrow_drop_down</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile @click="saveFattura()">
                          <v-list-tile-title>Salva in bozza</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="confirmChiusura = true">
                          <v-list-tile-title>Salva e chiudi fattura</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                    <v-btn
                      :disabled="!validFattura"
                      @click="stampaFattura()">
                      Stampa
                    </v-btn>
                    <v-dialog v-model="confirmChiusura" persistent max-width="250" transition="dialog-bottom-transition">
                      <v-card>
                        <v-card-title class="headline">Sicuro?</v-card-title>
                        <v-card-text>
                          Stai per chiudere la fattura, una volta chiusa non sarà più modificabile
                        </v-card-text>
                        <v-card-actions>
                          <v-btn @click.native="confirmChiusura = false">Annulla</v-btn>
                          <v-btn color="primary" @click.native="chiudiFattura()">Salva e chiudi</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>
            <v-stepper-content :step="2">
              <!-- mail -->
              <v-container fluid v-if="fattura">
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-form ref="formMail" v-model="validFormMail">
                      <v-text-field
                        label="Destinatario"
                        v-model="fattura.to"
                        prepend-icon="contacts"
                        :rules="[$rules.email, $rules.required]" />
                      <v-combobox
                        label="cc"
                        multiple
                        small-chips
                        cache-items
                        clearable
                        deletable-chips
                        v-model="fattura.cc"
                        prepend-icon="contacts"
                        :rules="[$rules.isArrayOfEmails]" />
                      <v-combobox
                        label="ccn"
                        multiple
                        small-chips
                        cache-items
                        clearable
                        deletable-chips
                        v-model="fattura.ccn"
                        prepend-icon="contacts"
                        :rules="[$rules.isArrayOfEmails]" />
                      <v-text-field
                        label="Oggetto"
                        v-model="fattura.subject"
                        prepend-icon="edit"
                        :rules="[$rules.required]" />
                      <v-textarea
                        auto-grow
                        label="Messaggio"
                        v-model="fattura.message"
                        :rules="[$rules.maxlength(4000), $rules.required]" />
                    </v-form>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="mt-4">
                  <v-flex>
                    <v-btn
                      color="primary"
                      :disabled="!validFormMail"
                      @click="send()">
                      Invia
                    </v-btn>
                    NB: La mail verrà automaticamente inviata per CC anche a te.
                  </v-flex>
                </v-layout>
              </v-container>
              <!--/ mail -->
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-layout class="pb-4">
            <v-flex>
              <template v-for="(s, index) in steps">
                <div v-if="s.number === step" :key="index">
                  <span class="mr-4" v-if="steps[index - 1]">
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index - 1)">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index - 1].name }}
                    </span>
                  </span>
                  <span class="ml-4" v-if="steps[index + 1]">
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index + 1].name }}
                    </span>
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index + 1)">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </span>
                </div>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fatturaMixin } from '@/components/fatturazione/fatturaMixin'
import _clone from 'lodash/clone'
import { $EventBus } from '@/eventBus'
import currencyInput from '@/components/common/CurrencyInput'
import datePicker from '@/components/common/DatePicker'
import itemUpdated from '@/components/common/ItemUpdated'
import fatturaStatus from '@/components/fatturazione/FatturaStatus'
export default {
  mixins: [fatturaMixin],
  data: () => ({
    ready: false,
    fattura: {},
    clienti: [],
    cliente: {},
    banche: [],
    voci: [],
    voce: {},
    ordini: [],
    iva: [],
    selectedOrdini: [],
    validIntestazione: false,
    validFormVoce: false,
    validFormVoceMagazzino: false,
    confirmChiusura: false,
    dialogVoce: false,
    dialogVoceStock: false,
    validFormMail: false,
    fab: false,
    step: 1,
    steps: [
      { number: 1, name: 'Informazioni generali' },
      { number: 2, name: 'Invio mail' }
    ]
  }),
  components: {
    currencyInput,
    itemUpdated,
    fatturaStatus,
    datePicker
  },
  computed: {
    validFattura () {
      return this.validIntestazione && this.voci && this.voci.length
    },
    voceModalTitle () {
      return this.voce && this.voce.voce_fattura_id ? this.voce.descrizione : 'Nuova voce'
    },
    importo () {
      if (this.voce.qta && (this.voce.prezzo || this.voce.prezzo === 0)) {
        return this.voce.qta * this.voce.prezzo
      }
      return null
    },
    anni () {
      const currentYear = (new Date()).getFullYear()
      return [currentYear, currentYear - 1]
    },
    mesi () {
      var month = []
      month[0] = 'Gennaio'
      month[1] = 'Febbraio'
      month[2] = 'Marzo'
      month[3] = 'Aprile'
      month[4] = 'Maggio'
      month[5] = 'Giugno'
      month[6] = 'Luglio'
      month[7] = 'Agosto'
      month[8] = 'Settembre'
      month[9] = 'Ottobre'
      month[10] = 'Novembre'
      month[11] = 'Dicembre'
      return month.map((m, i) => {
        return {
          text: m,
          value: i + 1
        }
      })
    }
  },
  methods: {
    canAddVoce () {
      if (this.fattura.tipo === 'nota') {
        return (this.cliente.aliquota || this.cliente.aliquota === 0) && this.fattura.banca_id && this.fattura.bozza
      } else {
        return (this.cliente.aliquota || this.cliente.aliquota === 0) && this.fattura.banca_id && this.fattura.bozza && !this.fattura._id
      }
    },
    changeStep (index) {
      this.step = this.steps[index].number
      if (index === 1 && !this.fattura.message) {
        let tipo
        if (this.fattura.tipo === 'nota') tipo = 'nota di credito'
        else tipo = 'fattura'
        this.fattura.message = `Spett.le ${this.cliente.ragione_sociale},

in allegato inviamo la ${tipo} n. ${this.fattura.codice}.

Distinti saluti.`
      }
    },
    getClienti () {
      return new Promise((resolve, reject) => {
        this.$plsqlMethod('fattura_stock', 'get_clienti', { }) // da fare metodo per fatture
          .then(response => {
            this.clienti = response.data
            resolve()
          })
          .catch(err => {
            console.log('err', err)
            $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
            reject(err)
          })
      })
    },
    setCliente (idCliente) {
      // this.fattura.banca_id = null
      this.cliente = this.clienti.find((c) => c.cliente_id === idCliente)
      this.banche = this.cliente.banche
    },
    changeCliente (idCliente) {
      this.setCliente(idCliente)
      this.fattura.banca_id = null
      if (!this.fattura._id) this.fattura.marca = this.cliente.marca
    },
    fattureOnline () {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'post',
          url: '/fattureonline/' + this.fattura.tipo + '/stock/crea',
          data: { id: this.fattura._id }
        }).then((response) => {
          $EventBus.$emit('message', { type: 'success', text: 'Documento salvato su fatture in cloud' })
          resolve({ id: response.data.new_id, token: response.data.token })
        }).catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il documento su fatture in cloud' })
          reject(err)
        })
      })
    },
    salvaFattura () {
      return new Promise((resolve, reject) => {
        this.$plsqlSave('fattura_stock', this.fattura)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      })
    },
    saveFattura () {
      this.salvaFattura()
        .then((fattura) => {
          this.fattura = fattura
          if (this.$route.params.id !== fattura._id) { // primo salvatggio
            this.$router.push({ name: 'fatturaStock', params: { id: fattura._id } })
          }
          $EventBus.$emit('message', { type: 'success', text: 'Fattura salvata' })
        })
    },
    updateFattura () {
      return new Promise((resolve, reject) => {
        this.$plsqlMethod('fattura_stock', 'update_id_esterno', this.fattura)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      })
    },
    chiudiFatturaDB () {
      return new Promise((resolve, reject) => {
        this.$plsqlMethod('fattura_stock', 'chiudi', { fattura_id: this.fattura._id })
          .then((response) => {
            this.confirmChiusura = false
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Fattura chiusa'
            })
            resolve(response.data.progressivo)
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            reject(err)
          })
      })
    },
    chiudiFattura () {
      // controlli
      if (!this.fattura.cliente_id_esterno || this.fattura.cliente_id_esterno === '') {
        $EventBus.$emit('message', { type: 'error', text: 'Cliente non registrato su fatture in cloud' })
        this.confirmChiusura = false
        return
      }
      if (!this.fattura.pagamento_id_esterno || this.fattura.pagamento_id_esterno === '') {
        $EventBus.$emit('message', { type: 'error', text: 'Metodo di pagamento del cliente non registrato su fatture in cloud' })
        this.confirmChiusura = false
        return
      }
      if ((!this.fattura.codice_fat || this.fattura.codice_fat === '') && (!this.fattura.mail_pec || this.fattura.mail_pec === '')) {
        $EventBus.$emit('message', { type: 'error', text: 'Il codice cliente o la mail PEC sono necessari per l\'invio della fattura elettronica' })
        this.confirmChiusura = false
        return
      }
      if (!this.fattura.data_scadenza || this.fattura.data_scadenza === '') { // questo non dovrebbe mai succede, l'ho messo perchè abbiamo dati vecchi e sporchi
        $EventBus.$emit('message', { type: 'error', text: 'Data scadenza non compilata' })
        this.confirmChiusura = false
        return
      }
      for (let v of this.fattura.voci) {
        if (!v.cod_iva || v.cod_iva === '') {
          $EventBus.$emit('message', { type: 'error', text: 'Iva non correttamente registrata per alcuni componenti della fattura' })
          this.confirmChiusura = false
          return
        }
      }

      // chiudere fattura e farsi tornare il progressivo
      this.chiudiFatturaDB()
        .then((progressivo) => {
          this.fattura.progressivo = progressivo
          this.fattura.bozza = 0
          // mandare a fattureincloud
          this.fattureOnline()
            .then((idEsterno) => {
              this.fattura.id_esterno = { id: idEsterno.id }
              // updatare la fattura con l'id esterno
              this.updateFattura()
                .then((fattura) => {
                  this.fattura = fattura
                  this.$router.push({ name: 'fattureStock' })
                })
            })
            .catch((err) => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Errore invio fatture in cloud' })
              // errore fatture online, rimetto a bozza
              this.fattura.bozza = 1
              this.updateFattura()
                .then((fattura) => {
                  this.fattura = fattura
                  this.confirmChiusura = false
                })
            })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore chiusura fattura' })
          this.confirmChiusura = false
        })
    },
    stampa () {
      return new Promise((resolve, reject) => {
        let es = this.creaDatiEsenzioni()
        this.fattura.esenzioni = es.esenzioni
        this.fattura.voci_iva = es.ive
        if (this.fattura.voci_iva.length === 1) this.fattura.desc_iva = this.fattura.voci_iva[0].descr; this.fattura.aliquota = this.fattura.voci_iva[0].aliquota
        this.getTipipag().then((tipo) => {
          this.fattura.modalita_pagamento = tipo.nome_metodo
          this.$http({
            method: 'post',
            url: '/pdf/fattura/stock',
            responseType: 'blob',
            data: { fattura: this.fattura }
          }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa della fattura' })
          })
        })
      })
    },
    stampaFattura () {
      if (this.fattura.bozza) {
        this.salvaFattura()
          .then((fattura) => {
            this.fattura = fattura
            if (this.$route.params.id !== fattura._id) { // primo salvatggio
              this.$router.push({ name: 'fatturaStock', params: { id: fattura._id } })
            }
            this.stampa()
          })
      } else {
        this.stampa()
      }
    },
    ricalcolaFattura (voci) {
      this.voci = voci
      let totale = this.fattura.marca || 0
      voci.forEach(function (v) {
        totale += v.qta * v.prezzo * (100 + v.aliquota) / 100
      })
      this.fattura.totale = (totale).toFixed(2)
    },
    leggiFattura () {
      return new Promise((resolve, reject) => {
        this.$plsqlRead('fattura_stock', this.$route.params.id)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
            console.log(err)
            $EventBus.$emit('message', {
              type: 'error',
              text: 'Errore lettura nota'
            })
          })
      })
    },
    leggiVoci () {
      return new Promise((resolve, reject) => {
        this.$plsqlMethod('fattura_stock', 'voci', { id: this.fattura._id })
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
            console.log(err)
            $EventBus.$emit('message', {
              type: 'error',
              text: 'Errore lettura voci fattura'
            })
          })
      })
    },
    salvaVoce () {
      return new Promise((resolve, reject) => {
        this.$plsqlMethod('fattura_stock', 'save_voce', this.voce)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
            console.log(err)
            $EventBus.$emit('message', {
              type: 'error',
              text: 'Errore lettura voci fattura'
            })
          })
      })
    },
    openVoce (voce) {
      if (voce) {
        this.voce = _clone(voce)
      } else { // nuova
        this.voce.aliquota = this.cliente.aliquota
        this.voce.cod_iva = this.cliente.iva_id + '' // è una stringa per la select
      }
      this.dialogVoce = true
    },
    closeVoce () {
      this.dialogVoce = false
      this.$refs.formVoce.reset()
      this.voce = {}
    },
    eliminaVoce (voce) {
      this.$plsqlMethod('fattura_stock', 'delete_voce', { voce_fattura_id: voce.voce_fattura_id })
        .then(response => {
          this.leggiVoci()
            .then((voci) => {
              this.ricalcolaFattura(voci)
            })
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Voce eliminata'
          })
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore cancellazione voce'
          })
        })
    },
    saveVoce () {
      if (!this.voce.ordine) {
        this.voce.ordine = this.voci && this.voci.length ? this.voci[this.voci.length - 1].ordine + 1 : 1
      }
      this.voce.aliquota = this.iva.find((el) => { return el.cod_iva === this.voce.cod_iva }).valore_iva
      if (!this.fattura._id) { // prima devo salvare
        this.salvaFattura()
          .then((fattura) => {
            this.fattura = fattura
            this.voce.fattura_id = this.fattura._id
            this.salvaVoce()
              .then((voce) => {
                this.closeVoce()
                this.leggiVoci()
                  .then((voci) => {
                    this.ricalcolaFattura(voci)
                    $EventBus.$emit('message', {
                      type: 'success',
                      text: 'Voce salvata'
                    })
                  })
              })
          })
      } else {
        this.voce.fattura_id = this.fattura._id
        this.salvaVoce()
          .then((voce) => {
            this.closeVoce()
            this.leggiVoci()
              .then((voci) => {
                this.ricalcolaFattura(voci)
                $EventBus.$emit('message', {
                  type: 'success',
                  text: 'Voce salvata'
                })
              })
          })
      }
    },
    openVoceStock () {
      this.fattura.anno = (new Date()).getFullYear()
      this.fattura.mese = (new Date()).getMonth() + 1
      this.dialogVoceStock = true
    },
    insertVoceStock () {
      this.fattura.calcolavoci = 1
      this.salvaFattura()
        .then((fattura) => {
          this.fattura = fattura
          if (this.$route.params.id !== fattura._id) { // primo salvatggio
            this.$router.push({ name: 'fatturaStock', params: { id: fattura._id } })
          }
          $EventBus.$emit('message', { type: 'success', text: 'Calcolo Voci di magazzino in corso: i dati saranno visibili tra qualche minuto...' })
          this.$router.push({ name: 'fattureStock' })
          /* this.fattura.calcolavoci = 0
          this.dialogVoceStock = false
          this.leggiVoci()
            .then((voci) => {
              this.ricalcolaFattura(voci)
            }) */
        })
    },
    send () {
      this.$http({
        method: 'post',
        url: `/mail/fattura/stock`,
        data: { fattura: this.fattura }
      }).then((response) => {
        this.$plsqlMethod('fattura_stock', 'mail', { idf: this.fattura._id, idm: response.data.mail_id })
          .then(() => {
            $EventBus.$emit('message', { type: 'success', text: 'Mail inviata!' })
          })
          .catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile  aggiornare l’id della mail' })
          })
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile inviare la mail' })
      })
    },
    getIva () {
      this.$http({
        method: 'get',
        url: `/fattureonline/iva`
      }).then((response) => {
        this.iva = response.data
      }).catch((err) => {
        $EventBus.$emit('message', { type: 'error', text: 'Errore recupero lista iva da fattureInCloud' + err.errMessage })
      })
    }
  },
  watch: {
    importo: function (newVal, oldVal) {
      this.voce.imponibile = newVal
    },
    step: function (newVal, oldVal) {
      if (newVal === 2) {
        this.fattura.to = this.cliente.mail_fattura
      }
    }
  },
  mounted () {
    this.getIva()
    this.getClienti()
      .then(() => {
        if (this.$route.params.id !== 'nota' && this.$route.params.id !== 'fattura') {
          this.leggiFattura()
            .then((fattura) => {
              this.fattura = fattura
              if (this.fattura._id) {
                this.setCliente(this.fattura.cliente_id)
                this.leggiVoci()
                  .then((voci) => {
                    this.ricalcolaFattura(voci)
                    this.ready = true
                  })
              }
            })
        } else {
          this.fattura = { // nuova
            bozza: 1,
            tipo: this.$route.params.id,
            area: 'stock',
            data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
          }
          this.ready = true
        }
      })
  }
}
</script>
